<template lang="pug">
  .recipes-modal-table__row.recipes-modal-table__row--body
    .float-left
      template(v-if="numIndex")
        font-awesome-icon(
                size="sm"
                icon="bars").mr-2.pointer-cursor
        span {{ index + 1 }}.
      template(v-else)
        div.mr-3
        span *
          .mr-1
    .recipes-modal-table__column
        v-select(
          :label="$t('base.ingredient')"
          :options="options"
          getFullItem=true
          v-model="item.ingredient"
          @input="parseIngredient"
        ).mb-1
    .recipes-modal-table__column
      .recipes-modal-table__quantity.mt-2
        v-checkbox(
          v-model="item.premix"
          @input="onChangePremix"
        )
    .recipes-modal-table__column
      .recipes-modal-table__quantity.mt-2
        v-checkbox(
          v-model="item.oil"
          :disabled="disableOilCheckbox"
          @input="onChangeOil"
        )
    .recipes-modal-table__column
      .recipes-modal-table__quantity.mt-2
        input.recipes-modal-table__field.large(
          type="number"
          min="0"
          max="100"
          @focus="onFocus"
          @blur="onBlur"
          v-model="item.percent")
        .recipes-modal-table__percent %
    .recipes-modal-table__column
      button(
        v-if="showRemove"
        @click="removeItem").recipes-modal-table__button
        i.i-close

</template>

<script>
export default {
  name: 'RecipesModalTableRow',

  props: {
    index: Number,
    numIndex: Boolean,
    item: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Array,
      default: () => ([])
    },
    showRemove: Boolean,
    premixNotChecked: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    removeItem () {
      this.$emit('remove-item', this.item.id)
    },

    onFocus (e) {
      if (e.target.value === '0') {
        e.target.value = ''
      }
    },

    onBlur (e) {
      if (e.target.value === '') {
        e.target.value = '0'
      }
    },

    onChangePremix (value) {
      this.item.premix = value
      this.$emit('change-premix', this.item)
      this.$emit('change-oil', this.item)
    },

    onChangeOil (value) {
      this.item.oil = value
      this.$emit('change-oil', this.item)
    },

    parseIngredient (ingredient) {
      this.item.ingredient = ingredient.id
      this.item.autoload = ingredient.autoload
    }
  },

  computed: {
    disableOilCheckbox () {
      if (this.premixNotChecked) {
        return true
      }

      return this.item.premix
    }
  }
}
</script>

<style lang="scss" scoped>
  .float-left {
    align-items: center;
    display: flex;
  }
  .pointer-cursor:hover {
    cursor: pointer;
  }
</style>
