<template lang="pug">
  .recipes-modal-table
    .recipes-modal-table__header
      .recipes-modal-table__row.recipes-modal-table__row--header
        h4.recipes-modal-table__column {{ 'base.ingredient' | translate }}
        h4.recipes-modal-table__column {{ 'base.premix_base' | translate }}
        h4.recipes-modal-table__column {{ 'base.composite_premixes' | translate }}
        h4.recipes-modal-table__column {{ 'modals.recipe.create.quantity' | translate }} (%)
        h4.recipes-modal-table__column
    .recipes-modal-table__body

    //DRAGGABLE INGREDIENTS
    draggable(
    :list="list"
    @start="dragging = true"
    @end="dragging = false")
      recipes-modal-table-row(
        v-for="(ingredient, index) in list"
        :key="ingredient.id"
        :item="ingredient"
        :options="ingredientsList"
        :show-remove="showRemove"
        :index="index"
        :numIndex="true"
        :premixNotChecked="premixNotChecked"
        @remove-item="removeItem"
        @change-premix="changePremix"
        @change-oil="changeOil")

    //UNDRAGGABLE INGREDIENTS
    recipes-modal-table-row(
        v-for="(ingredient, index) in freezeList"
        :key="ingredient.id"
        :item="ingredient"
        :options="ingredientsList"
        :show-remove="showRemove"
        :index="index"
        :numIndex="false"
        :premixNotChecked="premixNotChecked"
        @remove-item="removeItem"
        @change-premix="changePremix"
        @change-oil="changeOil")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import draggable from 'vuedraggable'

import RecipesModalTableRow from './RecipesModalTableRow'

export default {
  name: 'RecipesModalTable',

  components: {
    RecipesModalTableRow,
    draggable
  },

  data () {
    return {
      dragging: false
    }
  },

  props: {
    list: {
      type: Array,
      default: () => ([])
    },
    freezeList: {
      type: Array,
      default: () => ([])
    },
    showRemove: Boolean
  },

  computed: {
    ...mapGetters(['ingredientsList']),
    premixNotChecked () {
      const searchPremix = function (ingredient) {
        return ingredient.premix
      }

      return !(this.list.some(searchPremix) || this.freezeList.some(searchPremix))
    }
  },

  async mounted () {
    await this.fetchIngredientsList()
  },

  methods: {
    ...mapActions(['fetchIngredientsList']),

    removeItem (id) {
      this.$emit('remove-item', id)
    },

    changePremix (ingredient) {
      let runUpdateLists = false
      const test = function (item) {
        if (item.id !== ingredient.id) {
          runUpdateLists = runUpdateLists || item.premix
          item.premix = false
        } else if (ingredient.oil) {
          item.oil = false
        }
      }
      this.list.forEach(test)
      this.freezeList.forEach(test)
      if (runUpdateLists) {
        this.$emit('freeze-item-update')
      }
    },

    changeOil (ingredient) {
      this.freezeList.forEach(item => {
        if (item.id === ingredient.id && ingredient.oil) {
          item.premix = false
        }
      })
      this.$emit('list-oil', ingredient)
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables.scss';

  .recipes-modal-table {
    &__row {
      padding: .8rem 2.4rem 0;
      position: relative;
      display: flex;
      gap: 4px;
      justify-content: space-between;

      &--header {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border: 1px solid $color-gray-light-2;
        background-color: $color-gray-light-3;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        flex: 1 1 auto;

        .recipes-modal-table {
          &__column {
            padding-right: 2rem;
            color: $color-gray;
            overflow: hidden;
            flex: 0 0 20%;
            &:first-child {
              flex: 0 0 35%;
            }

            &:nth-child(2) {
              flex: 0 0 10%;
            }

            &:nth-child(3) {
              flex: 0 0 12%;
            }

            &:last-child {
              flex: 0 0 1%;
            }
          }
        }
      }

      &--body {
        padding: .8rem 1.5rem 0;
        background-color: $color-gray-light-4;
        border-left: 1px solid $color-gray-light-2;
        border-right: 1px solid $color-gray-light-2;
        flex: 1 1 auto;

        &:last-child {
          padding-bottom: 1.5rem;
        }

        .recipes-modal-table {
          &__column {
            flex: 0 0 15%;

            &:nth-child(2) {
              flex: 0 0 27%;
            }

            &:nth-child(5) {
              flex: 0 0 18%;
            }

            &:last-child {
              flex: 0 0 7%;
              text-align: center;
            }
          }
        }
      }
    }

    &__column {
      font-size: 1.5rem;
      line-height: 1.8rem;
      font-weight: 400;
      color: $color-black;
    }

    &__quantity {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &__field {
      position: relative;
      &.large {
        width: 6.3rem;
      }
      width: 3.5rem;
      text-align: center;
      padding-bottom: .8rem;
      font-size: 1.5rem;
      line-height: 1.8rem;
      border-bottom: 1px solid rgba(175, 175, 175, 0.2);

      &:focus {
        outline: none;
      }
    }

    &__percent {
      padding-bottom: .8rem;
      font-size: 1.5rem;
      line-height: 1.8rem;
    }

    &__button {
      height: 100%;
      padding: 1rem;
      outline: none;
    }
  }
</style>
